/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-20 09:40:03
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-26 02:01:43
 */
import { mapGetters } from 'vuex'
import UserAdd from '../UserAdd'
import HandleData from '@/service/User'
import EnumMap from '@/plugins/enumMap'
export default {
  name: 'UserTable',
  components: {
    UserAdd
  },
  props: {
    roleList: {
      type: Array,
      default: () => []
    },
    agentList: {
      type: Array,
      default: () => []
    },
    customList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      queryForm: {
        role_id: 0,
        status: 0,
        keyword: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'enum_map'
    ])
  },
  methods: {
    userStatus (val) {
      const name = new EnumMap('user_status').mapping(val)
      return name
    },
    tableSearch (reset) {
      if (reset) {
        this.$refs['w-table'].resetList()
      } else {
        this.$refs['w-table'].getList()
      }
    },
    async edit (row) {
      const userEdit = this.$refs.userEdit
      const data = await this.$wPost('/admin/user/get.do', { id: row.id })
      if (data === false) {
        return
      }
      await userEdit.updatedForm(data)
      userEdit.show()
    },
    async updateStatus (e) {
      this.$refs['w-table'].updateLoading(true)
      const payload = new HandleData(e).updateStatus()
      const data = await this.$wPost('/admin/user/update_status.do', payload)
      if (data === true) {
        this.$wToast.success(`${payload.status === 1 ? '启用' : '停用'}成功`)
        this.tableSearch(true)
      }
      this.$refs['w-table'].updateLoading(false)
    },
    handleCommand (e) {
      switch (e.type) {
        case 'status':
          this.updateStatus(e)
          break;
        case 'delete':
          this.delete(e.id)
          break;
        default:
          break;
      }
    },
    delete (id) {
      const self = this
      self.$confirm('您确认删除此用户吗？', '', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'w-table-delete'
      }).then(async () => {
        self.$refs['w-table'].updateLoading(true)
        const payload = Object.assign({}, {
          id
        })
        const data = await self.$wPost('/admin/user/delete.do', payload)
        if (data === true) {
          self.$wToast.success('删除成功')
          self.tableSearch(true)
        } else {
          self.$refs['w-table'].updateLoading(false)
        }
      }).catch(() => {});
    }
  }
}
